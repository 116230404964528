import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Link from 'ui-kit/link/link';

import ErrorScreenLayout from 'components/layouts/error-screen/error-screen.layout';

import { getPhoneNumber } from 'util/globalVariables';

import useResetToken, { flowTypes } from 'hooks/useResetToken';

import './no-prescriptions-eligible.styles.scss';

const NoPrescriptionsEligible = ({
    data,
    location
}: {
    data: GatsbyTypes.NoPrescriptionsEligibleDataQuery;
    location: Location;
}) => {
    const { t } = useTranslation();

    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow');

    useResetToken(flowParameter as flowTypes);

    return (
        <ErrorScreenLayout title={t('pages.noPrescriptionsEligible.title')}>
            <Container className="no-prescriptions-eligible">
                <Row>
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                        <p className="mobile-auto-refill-subtitle1">
                            {t('pages.noPrescriptionsEligible.subtitle.paragraphOne')}
                        </p>

                        <p
                            className="mobile-auto-refill-subtitle2"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.noPrescriptionsEligible.subtitle.paragraphTwo', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} className="d-flex align-items-center justify-content-center">
                        <Link
                            to={'/sign-in'}
                            label={t('pages.noPrescriptionsEligible.buttonLabel')}
                            variant="cta-button"
                            dataGALocation="PrescriptionsOrdered-Signin"
                            ariaLabel="PrescriptionsOrdered-Signin"
                        />
                    </Col>
                </Row>
            </Container>
        </ErrorScreenLayout>
    );
};

export default NoPrescriptionsEligible;

export const query = graphql`
    query NoPrescriptionsEligibleData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        easyRefillFlowDesktop: file(relativePath: { eq: "assets/images/feather-background-white.jpeg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
